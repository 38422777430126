.privacy-policy {
  margin-left: 7%;
  margin-right: 7%;
  padding: 20px;
  font-family: Arial, sans-serif;

  line-height: 1.6;
}

.privacy-policy h1 {
  font-size: 2.5rem;
  background: -webkit-linear-gradient(#005aff, #25aadd);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  margin-bottom: 10px;
}

.effective-date {
  font-size: 0.9rem;
  color: #777;
  text-align: center;
  margin-bottom: 30px;
}

.privacy-policy h2 {
  font-size: 1.5rem;
  margin-top: 20px;
  color: #444;
}

.privacy-policy h3 {
  font-size: 1.25rem;
  margin-top: 15px;
 
}

.privacy-policy p {
  margin-bottom: 15px;
 
}

.privacy-policy ul {
  list-style-type: disc;
  padding-left: 20px;

}

.privacy-policy li {
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .privacy-policy {
    padding: 10px;
  }

  .privacy-policy h1 {
    font-size: 2rem;
  }

  .privacy-policy h2 {
    font-size: 1.25rem;
  }

  .privacy-policy h3 {
    font-size: 1.1rem;
  }
}
