.callsButtonContainer button{
    margin-left: 10px;
    padding: 9px;
}

/* Style for the selected button */
.selected-button {
  background-color: #007bff !important; 
  color: white !important; 
  border-color: #007bff !important; 
}

/* .p-button-outlined {
  border-color: #007bff; 
  color: #007bff; 
} */

/* Style when the button is hovered or focused */
/* .p-button-outlined:hover {
  background-color: #f0f0f0; 
} */
