:root {
  --gradient-color: -webkit-linear-gradient(#005aff, #25aadd);
}

.Text-Gradientt {
  background: var(--gradient-color);
}
.homePricing {
  width: 100%;
  overflow: hidden;
}
