.loginPageParent {
  overflow: hidden;
}
.loginPageParent .lg\:col-6 {
  padding: 0%;
}

.loginNav-icon {
  width: 85px;
  border-radius: 50px;
  box-shadow: rgba(233, 190, 190, 0.24) 0px 30px 80px;
  margin-left: 15px;
}

.loginLeftSideCard {
  height: 100%;
}

.topImg {
  display: flex;
}

.topImg h1{

  text-align: center;
  color: black;
  margin: 40px 0 0 90px;

}

.topImg .logo-Img {
  margin: 7px 5px 5px 15px;
}

.login-left-img {
  margin: auto;
  width: 50%;
  padding: 60px 0 0 0;
}

.login-left-img .middleLogo{

  height: 300px;
}

.loginCardDiv {
  display: flex;
  /* padding: 1% 0px; */
  /* width: 100%; */
  background-color: #4152b3;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.loginCard {
  padding: 10px;
  /* border-radius: 20px; */
}
div.loginCardDiv > div > form > table > tr > td {
  padding: 3px 3px;
}

.loginCard input {
  width: 100%;
}
.loginCard label {
}
.loginCard a {
  display: block;
  font-size: 15px;
  color: white;
  /* letter-spacing: 1px; */
}
.grey-color {
  color: white;
  font-size: 0.9rem;
}

.svgIconLogin {
  color: black;
}

.p-password-input.p-inputtext.p-component.p-password-input,
.customInputField.p-inputtext.p-component.customInputField {
  padding-left: 30px;
  box-sizing: border-box;
}
.loginCard button {
  width: 100%;
  border-radius: 20px;
}
.heading-for-login {
  font-size: 30px;
  text-align: center;
  letter-spacing: 0.5px;
  color: white;
  margin: 10px 0px;
}

.Captcha {
  color: white;
  text-decoration: line-through;
  user-select: none;
  background-color: black;
  border-radius: 5px;
  padding: 10px 5px;
  width: 80%;
  font-style: italic;
  font-size: 1.5rem;
  text-align: center;
}
.invalid-text {
  font-size: 0.8rem;
  margin: 2px 20px;
  color: red;
  font-weight: 500;
}

.p-password {
}
.loginCard table td {
  display: block;
}
@media screen and (max-height: 500px) {
  .loginCardDiv {
    height: 100%;
    /* background-color: aqua; */
  }
}
@media screen and (max-width: 483px) {
  .loginCard {
    padding: 15px 10px;
  }
}
