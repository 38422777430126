.DA-Hero {
  width: 100%;
  height: 100%;
  /* padding: 0px 10%; */
  overflow: hidden;
  /* background: #f8f9fd; */
}
.da-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 0px 10%;
  gap: 10px;
  margin-top: 30px;
}
.da-left h1 {
  font-size: 4.1vw;
  line-height: 4.9vw;
  margin: 12px 0px;
  background: -webkit-linear-gradient(#005aff, #25aadd);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.da-left p {
  color: #21282d;
  width: 85%;
  text-align: justify;
  font-size: 1.2rem;
}
.da-right img {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.da-bottom {
  grid-column: span 2;
    margin-top: 20px;
}
.da-bottom h2 {
  text-align: center;
  grid-column: span 2;
  font-size: 1.9rem;
  background: -webkit-linear-gradient(#005aff, #25aadd);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

}
.da-bottom p {
  color: #21282d;
  text-align: justify;
  font-size: 1.2rem;
  line-height: 2rem;
}
.why-da-anylitics {
  grid-column: span 2;
}
.why-da-anylitics h4 {
  text-align: center;
  font-size: 1.9rem;
  background: -webkit-linear-gradient(#005aff, #25aadd);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.da-cards {
  /*parent*/
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;

  /* padding: 2% 0 5% 0; */
}
.da-card {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 1rem;
  font-size: 1.3rem;
  border-radius: 20px;
}
.da-card h5 {
  font-size: 1.5em;
}
.da-card p {
  font-size: 1.3rem;
}
.da-features {
  grid-column: span 2;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}
.da-features h4 {
  text-align: center;
  grid-column: span 2;
  font-size: 1.9rem;
  background: -webkit-linear-gradient(#005aff, #25aadd);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.da-features-card {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 1rem;
  border-radius: 20px;
  color: #21282d;
}
.da-features-card p {
  margin-top: -15px;
  font-size: 1rem;
  /* text-align: justify; */
  line-height: 1.7rem;
}

.da-features-card h5 {
  font-size: 1.4rem;
  background: -webkit-linear-gradient(#005aff, #25aadd);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.da-features-card img {
  height: 5em;
  border-radius: 20px;
}

.ourSolutionCrd-1,
.ourSolutionCrd-2 {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  margin: 1rem;
}

.ourSolutionCrd-1:hover,
.ourSolutionCrd-2:hover {
  transform: translateY(-10px);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

@media screen and (max-width: 900px) {
  .da-left h1 {
    font-size: 4vw;
    line-height: 4.9vw;
  }
  .da-left p {
    margin: 0;
    text-align: left;
    width: initial;
    margin-bottom: 10px;
  }
  .da-right {
    text-align: center;
  }

  .da-bottom > p {
    font-size: 0.8rem;
    text-align: justify;
  }

  .da-card {
    font-size: 2vw;
    /* margin: 20px 0; */
    padding: 0.8rem;
  }

  .da-features-card {
    font-size: 2vw;

    padding: 0.8rem;
  }
  .da-features-card {
    text-align: center;
  }
}
@media screen and (max-width: 536px) {
  .da-container {
    display: block;
  }
  .da-left h1 {
    font-size: 7vw;
    line-height: 7.5vw;
  }
  .da-left p {
    margin: 0;
    text-align: left;
    width: initial;
    margin-bottom: 10px;
  }
  .da-right {
    text-align: center;
  }
  .da-bottom h2 {
    font-size: 1.1rem;
  }
  .da-bottom > p {
    font-size: 0.8rem;
    text-align: justify;
  }
  .da-cards {
    display: block;
  }
  .da-card {
    font-size: 3.3vw;
    margin: 20px 0;
    padding: 0.8rem;
  }
  .da-features {
    display: block;
  }
  .da-features-card {
    font-size: 3.3vw;
    margin: 20px 0;
    padding: 0.8rem;
  }
  .da-features-card {
    text-align: center;
  }
}
