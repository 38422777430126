.homeMain {
  width: 100%;
  overflow: hidden;
}

.home-Call-links {
  text-decoration: none;
  color: black;
}

.home-Call-links:hover {
}

.home-content-subsection {
  padding: 20px 60px;
}

.homeFirstSection {
  background-color: white;
}

.home-heading {
  font-size: 3rem;
  font-weight: 600;
  color: #263238;
  text-transform: capitalize;
  margin-top: 4rem;
}
.home-para {
  padding: 30px 0px;
  font-size: 1.3rem;
  color: gray;
  font-weight: 500;
  line-height: 32px;
  text-transform: capitalize;
}

.ourSolutionCrd-1 {
}

.ourSolutionCrd-2 {
}

.Home-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* padding: 1% 0; */
  gap: 10px;
}

.Home-left h1 {
  font-weight: 300;
  font-size: 4.1vw;
  line-height: 4.9vw;
  margin: 12px 0px;
  background: -webkit-linear-gradient(#01579b, #00bcd4);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.Home-left p {
  color: #21282d;
  width: 85%;
  text-align: justify;
}

.contact-subsection-right {
  /* margin: 5px 20px; */
  border-radius: 30px;
}
.p-card .p-card-content {
  padding: 10px;
}
.p-card .p-card-body {
  padding: 0;
}
.contact-form-table {
  width: 100%;
  padding: 2% 0;
  margin-top: 2%;
}

.contact-form-table td {
  background-color: yellow;
}

.custom-datePicker .react-datepicker .homeFirstCard {
  padding: 0px 4%;
}

.input-field label {
  font-size: 1rem;
  font-weight: 500;
  margin-left: 5px;
  margin-bottom: 5px;
}

.confirmDialogForm table td {
  margin-bottom: 40px;
  border-bottom: 1px solid #ddd;
  text-align: left;
}

.ConfirmDialog .p-dialog .p-dialog-header {
  padding: 10px;
}

.input-field input {
  padding: 10px 15px;
  font-size: 1rem;
  border-radius: 20px;
  border: solid 1px #8d8d8d;
  letter-spacing: 1px;
  width: 95%;
  margin-top: 5px;
}

.input-field .react-tel-input input {
  padding: 10px 3.5rem;
  font-size: 1rem;
  border-radius: 20px;
  border: solid 1px #8d8d8d;
  letter-spacing: 1px;
  width: 100%;
  margin-top: 5px;
  margin-right: 10px;
}

.homeEmergencies ul li {
  margin: 10px 0px;
  font-size: 1em;

  font-weight: 300;
  color: #003366;
  list-style-type: none;
}
.homeEmergencies ul {
  margin: 0;
}

.emergenciesPoints {
  display: grid;
  grid-template-columns: 1.5rem auto;
  align-items: center;
}

.emergenciesPoints svg {
  color: #03a9f4;
  font-size: 1rem;
}

.p-panel {
  text-align: center;
}
.p-panel-header {
  background-color: white;
  text-align: center;
}

#pr_id_3_header .p-panel-title {
  margin-left: 5rem;
}

.thankYouDialog img {
  width: 100%;
  height: 12rem;
}

.custom-datePicker .react-datepicker {
  width: 100%;
  border-radius: 20px !important;
  font-family: "Montserrat", sans-serif !important;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  line-height: 1.2;
  font-size: 15px;
  padding: 4px;
}
.custom-datePicker .react-datepicker__month-container {
  width: 100%;
}

.custom-datePicker .react-datepicker__header {
  background-color: white;
  margin: 10px;
  padding: 5px;
}

.custom-datePicker .react-datepicker__day-names {
  font-weight: 700;
  width: 100%;
}

.custom-datePicker .react-datepicker__day-name {
  width: calc(87% / 7);
}
.custom-datePicker .react-datepicker__day {
  width: calc(87% / 7);
  padding: 5px;
  border-radius: 50% !important;
  font-weight: 500;
}

.ourSolutionCrd-1,
.ourSolutionCrd-2 {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  margin: 1rem;
}

.ourSolutionCrd-1:hover,
.ourSolutionCrd-2:hover {
  transform: translateY(-10px); /* Move the card upwards */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2); /* Add shadow for depth effect */
}

/* Default layout for larger screens (Tablet and Desktop) */

.homeFlex {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
}

.homeFlex p {
  line-height: 1.7rem;
  padding-left: 25px;
}

.homeFlex h2 {
  padding-left: 25px;
}

.homeFlexExpert {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  padding: 6.8px;
}

.homeFlexExpert p {
  line-height: 1.7rem;
  padding-left: 25px;
}
.OurSolutionsCard h1 {
  font-size: 2.3rem;
}

.homeFlexExpert h2 {
  padding-left: 25px;
}

/* .Selectappoint {
  flex: 1; 
} */

/* Media Query for Mobile devices */
@media (max-width: 600px) {
  .home-heading {
    width: 100%;
    font-size: 1.7rem;
    font-weight: 800;
  }

  .home-para {
    font-size: 1rem;
  }

  .ourSolutionCrd-1 {
    margin: 0% !important;
  }

  .ourSolutionCrd-2 {
    margin: 0% !important;
  }

  .homeFlex {
    flex-direction: column;
    align-items: flex-start;
    align-items: center;
  }

  .custom-datePicker {
    width: 100%;
  }

  .inputMessageAndDate {
    margin-left: 0px;
    margin-top: 10px;
  }

  .widthTimeSelect {
    width: 100%;
  }

  /* .w-7{
    width: 130% !important;
  } */

  .label-line {
    font-size: 16px;
  }

  .calllink {
    font-size: small;
  }
}

@media only screen and (min-width: 602px) and (max-width: 770px) {
  .ourSolutionCrd-1 {
    margin: 0% !important;
  }

  .ourSolutionCrd-2 {
    margin: 0% !important;
  }
}
