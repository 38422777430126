
/* ADD EMPLOYEE CSS */
.addEmployeeContainer td {
  padding: 10px 5px;
}

@media screen and (max-width: 828px) {
  .width-control td {
    display: block !important;
  }
  .width-control {
    width: 80vw;
  }
  .width-control input {
    width: 100% !important;
  }
}