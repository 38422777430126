.firstdivClients {
  display: flex;
  justify-content: space-between;
}

.firstdivClients h2 {
  margin-left: 1.3rem;
}
.addClientLeftImg {
  margin-top: 4rem;
}

