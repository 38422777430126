.sessionExpiryModel.p-dialog .p-dialog-header {
  padding: 0 !important;
}
.sessionExpiryModel{
    z-index: 999999 !important;
}
.sessionExpiryModel .p-dialog {
  z-index: 999999 !important;
}
.sessionExpiryModel.p-dialog .p-dialog-content {
  padding: 0 !important;

}
.sessionExpiryModel .modal-content {
  background-color: #fff;
  margin: auto;
  padding: 10px;
  border: 1px solid #888;
  /* width: 80%; */
  max-width: 550px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  z-index: 999999 !important;
}
.sessionExpiryModel .close-button:hover,
.sessionExpiryModel .close-button:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.sessionExpiryModel .modal-header {
  background: #7e57c2;
  color: white;
  padding: 10px 0;
  border-radius: 10px 10px 0 0;
}

.sessionExpiryModel .modal-body {
  margin: 0px 0;
  z-index: 999999 !important;
}

.sessionExpiryModel .modal-footer {
  background: #f1f1f1;
  padding: 10px 0;
  border-radius: 0 0 10px 10px;
}

.sessionExpiryModel button {
  background-color: #7e57c2;
  font-weight: 700;
  color: white;
  padding: 10px 20px;
  margin: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.sessionExpiryModel button:hover {
  background-color: #6432ba;
}
.sessionExpiryModel .modal-footer strong {
  color: #0e0024;
  font-size: 19px;
}
.sessionExpiryModel .modal-body > p:nth-child(2) {
  font-weight: 500;
  color: #ef6c00;
}
.sessionExpiryModel .modal-body > p:nth-child(1) {
  color: #0e0024;
  font-weight: 700;
}
.sessionExpiryModel .modal-footer p {
  font-size: small;
  color: #616161;
}
@media screen and (max-width: 650px) {
  .sessionExpiryModel {
    width: 90%;
    z-index: 999999 !important;
  }
}
@media screen and (max-width: 440px) {
  .modal-header h2 {
    font-size: 1rem;
    margin: 6px 0;
  }
  .sessionExpiryModel .modal-body > p:nth-child(2) {
    font-size: 13px;
  }
  .sessionExpiryModel .modal-body > p:nth-child(1) {
    font-size: 13px;
  }
  .sessionExpiryModel .modal-footer p {
    font-size: 11px;
  }
  .sessionExpiryModel .modal-footer strong {
    color: #0e0024;
    font-size: 1rem;
  }
  .sessionExpiryModel button {
    font-weight: 500;

    padding: 8px 8px;
    font-size: 13px;
    margin: 10px;
  }
}
