.contact-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.header {
  margin-bottom: 20px;
  color: #555;
  text-align: center;
}

.contact-content {
  display: flex;
  gap: 20px;
  max-width: 1000px;
  width: 100%;
  padding: 20px;
}

.contact-info {
  flex: 1;
  background-color: rgb(200, 206, 235);
  padding: 20px;
  border-radius: 8px;

  flex-direction: column;
  align-items: center;
  text-align: center;
}

.contact-info h3 {
  margin-bottom: 10px;
}

.info-item {
  margin: 10px 0;
  font-size: 1.1em;
}

.social-icons {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 100px;
}

.social-icon {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #7f3fc7;
  color: #fff;
  font-weight: bold;
  text-decoration: none;
}

.contact-form {
  flex: 2;
  padding: 20px;
}

.contact-form form {
  display: flex;
  flex-direction: column;
}

.contact-form label {
  margin-top: 15px;
  color: transparent; /* Make the text color transparent */
  background: -webkit-linear-gradient(
    #005aff,
    #25aadd
  ); /* Gradient background */
  background: linear-gradient(
    #005aff,
    #25aadd
  ); /* Fallback for other browsers */
  -webkit-background-clip: text; /* Clip background to text */
  background-clip: text; /* For other browsers */
}

.contact-form .react-tel-input input {
  border: none;
  border-bottom: 2px solid #005aff; /* Base border color */
  /* padding: 30px; */
  margin-top: 5px;
  outline: none;
  color: #333;
  width: 100%;
  background-color: #f5f5f5;
  transition: border-color 0.3s;
}

.contact-form input,
.contact-form textarea {
  border: none;
  border-bottom: 2px solid #005aff; /* Base border color */
  padding: 8px;
  margin-top: 5px;
  outline: none;
  color: #333;
  background-color: #f5f5f5;
  transition: border-color 0.3s;
}

.contact-form input:focus,
.contact-form textarea:focus {
  border-bottom: 2px solid #25aadd; /* Change border color on focus */
}

.contact-form textarea {
  resize: none;
  height: 100px;
}

.submit-button {
  margin-top: 15px;
  padding: 10px;
  background: -webkit-linear-gradient(
    #005aff,
    #25aadd
  ); /* Gradient background for WebKit browsers */
  background: linear-gradient(
    #005aff,
    #25aadd
  ); /* Fallback for other browsers */
  color: #fff;
  border: none;
  cursor: pointer;
  font-weight: bold;
  border-radius: 5px;
  transition: background 0.3s; /* Smooth transition effect */
}

.submit-button:hover {
  background: -webkit-linear-gradient(
    #25aadd,
    #005aff
  ); /* Reverse gradient on hover */
  background: linear-gradient(
    #25aadd,
    #005aff
  ); /* Fallback for other browsers */
}

/* Responsive Design */
@media (max-width: 768px) {
  .contact-content {
    flex-direction: column;
  }
}
