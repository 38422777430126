.cancellation-refunds {
  margin-left: 7%;
  margin-right: 7%;
  padding: 20px;
  font-family: Arial, sans-serif;
  color: #333;
  line-height: 1.6;
}

.cancellation-refunds h1 {
  font-size: 2rem;
  background: -webkit-linear-gradient(#005aff, #25aadd);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  margin-bottom: 20px;
}

.cancellation-refunds h2 {
  font-size: 1.5rem;
  margin-top: 20px;
  color: #444;
}

.cancellation-refunds p {
  margin-bottom: 15px;

}

@media (max-width: 768px) {
  .cancellation-refunds {
    padding: 10px;
  }

  .cancellation-refunds h1 {
    font-size: 2rem;
  }

  .cancellation-refunds h2 {
    font-size: 1.25rem;
  }
}
