:root {
  --gradient-color: -webkit-linear-gradient(#005aff, #25aadd);
}

nav {
  padding: 8px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 9999;
  background-color: white;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.my-icon {
  width: 85px;
  border-radius: 50px;
  box-shadow: rgba(233, 190, 190, 0.24) 0px 30px 80px;
  margin-left: 15px;
}
.dd-links {
  display: flex !important;
  align-items: center;
}
.dropdown-content .icon-div {
  margin: 0;
  padding: 0 5px 0 0;
}

nav ul li {
  display: inline-block;
  list-style: none;
  margin: 10px 15px;
}
nav ul li a,
#services {
  text-decoration: none;
  color: #333;
  font-size: 1rem;
  font-weight: 600;
}

/* .contactbtn:hover, */
/* .contactbtn.active */
nav ul li a:hover,
nav ul li a.active {
  border-bottom: 5px solid #5c6bc0;
}
.dropdownparent a:hover {
  border-bottom: 1px solid #ddd;
}

/* .contactbtn {
  font-size: 1rem;
  text-decoration: none;
  color: #333;
  margin-right: 15px;
  font-weight: 600;
} */
.btn {
  display: inline-block;
  text-decoration: none;
  padding: 14px 40px;
  color: #fff;
  background-image: var(--gradient-color);
  font-size: 14px;
  border-radius: 20px;
  border-top-right-radius: 0;
  transition: 0.5s;
}
.border-right {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 0;
}
.dropdownparent {
  display: inline-block;
  position: relative;
}
.btn:hover {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 0;
}
.dropdown-content {
  position: absolute;
  background-color: #039be5;
  min-width: 160px;
  max-width: 200px;
  width: max-content;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 5px;
  border-top: 5px solid #f8f9fd;
  top: 100%;
  left: 0px;
}

.dropdown-content a {
  color: #fff;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  border-bottom: 1px solid #ddd;
}

.dropdown-content a:hover {
  color: black;
  background-color: #f1f1f1;
}
.hamburger {
  display: none;
}
.hamburger svg {
  cursor: pointer;
}
.openNav {
  top: 100px;
}
.contact-icon {
  display: none;
}

.contact-section {
  margin-right: 1.8rem;
}

@media screen and (max-width: 900px) {
  .hamburger {
    display: block;
  }
  .contact-icon {
    display: block;
  }
  .contact-text {
    display: none;
  }
  nav > ul li {
    display: block;
    margin: 10px 0px;
  }
  nav ul li a,
  #services {
    font-weight: 300;
  }
  .dropdownparent {
    margin: 0;
    margin-left: 15px;
  }
  nav .btn {
    padding: 0.6rem 1rem;
  }
  .contact-section {
    margin-left: auto;
    margin-right: 10px;
  }
  .my-icon {
    width: 100px;
  }
  nav > ul {
    position: absolute;
    z-index: 100;
    top: -150px;
    left: 0px;
    margin-top: -12px;
    padding: 0;
    /* right: 40%; */
    block-size: auto;
    width: 100%;
    text-align: center;
    background-color: #f8f9fd;

    transition: 0.2s ease-in-out;
  }
}

.contact-section .btn {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.dialog-box {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  width: 300px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.dialog-content p {
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
}

.call-button {
  display: inline-block;
  margin-top: 15px;
  padding: 10px 20px;
  background: #4caf50;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  font-weight: bold;
}
