:root {
  --gradient-color: -webkit-linear-gradient(#005aff, #25aadd);
}

.parentDivDashboard {
  /* overflow-x: hidden; */
}
.Text-Gradient {
  background: var(--gradient-color);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.starForMandatoryInInputFields {
  color: red;
}

.displyToastTop .p-toast {
  z-index: 9999 !important;
}

.dialogAddForms .p-dialog-header {
  padding: 5px;
}

:root {
  --common-padding: 10px 13px;
  --common-transition: 0.2s;
}

/* From Uiverse.io by alexroumi */
.testButton {
  padding: 12px 15px;
  border: unset;
  border-radius: 60px;
  color: #212121;
  z-index: 1;
  background: #e8e8e8;
  position: relative;
  font-weight: 1000;
  font-size: 17px;
  -webkit-box-shadow: 4px 8px 19px -3px rgba(0, 0, 0, 0.27);
  box-shadow: 4px 8px 19px -3px rgba(0, 0, 0, 0.27);
  transition: all 250ms;
  overflow: hidden;
}

.testButton::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
  border-radius: 15px;
  background-color: #212121;
  z-index: -1;
  -webkit-box-shadow: 4px 8px 19px -3px rgba(0, 0, 0, 0.27);
  box-shadow: 4px 8px 19px -3px rgba(0, 0, 0, 0.27);
  transition: all 250ms;
}

.testButton:hover {
  color: #e8e8e8;
}

.testButton:hover::before {
  width: 100%;
}

.area {
  display: flex;
  background-color: white;
  width: fit-content;
  align-items: center;
  border-radius: 20px;
  font-weight: 400;
  cursor: pointer;
  height: 50px;
}
.area-right {
  padding: var(--common-padding);
  transition: var(--common-transition);
  border-radius: inherit;
}
.area-left {
  padding: var(--common-padding);
  transition: var(--common-transition);
  border-radius: inherit;
}
.active-link-css {
  background-color: black;
  color: white;
}

/* body
  > div.p-dialog-mask.p-dialog-center.p-component-overlay.p-component-overlay-enter.p-dialog-draggable.p-dialog-resizable {
  z-index: 9999 !important;
} */

body
  > div.p-dialog-mask.p-dialog-center.p-component-overlay.p-component-overlay-enter.p-dialog-draggable.p-dialog-resizable {
  z-index: 999 !important;
}

.homeCommingParent {
  width: 100vw;
  height: 100%;
  display: flex;
  justify-content: center;
}

.homeCommingParent img {
  width: 90vw;
  height: 90vh;
  background-repeat: no-repeat;
  padding: 25px;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
  border-radius: 20px;
}
