.terms-conditions {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    color: #333;
    line-height: 1.6;
  }
  
  .terms-conditions h1 {
    font-size: 2rem;
     background: -webkit-linear-gradient(#005aff, #25aadd);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .terms-conditions h2 {
    font-size: 1.5rem;
    margin-top: 20px;
    color: #444;
  }
  
  .terms-conditions h3 {
    font-size: 1.25rem;
    margin-top: 15px;
   
  }
  
  .terms-conditions p {
    margin-bottom: 15px;
    
  }
  
  @media (max-width: 768px) {
    .terms-conditions {
      padding: 10px;
    }
  
    .terms-conditions h1 {
      font-size: 2rem;
    }
  
    .terms-conditions h2 {
      font-size: 1.25rem;
    }
  
    .terms-conditions h3 {
      font-size: 1.1rem;
    }
  }
  