:root {
  --gradient-color: -webkit-linear-gradient(#005aff, #25aadd);
}

.Footer {
  background-color: hsl(0, 0%, 15%);
  color: #ffffff;
  padding: 15px 7%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}
.Footer ul li {
  list-style-type: none;
  /* margin: 8px 0; */
}
.list-margin li {
  /* margin: 15px 0; */
}
.footer-point-headings {
  padding-bottom: 10px;
  font-weight: 800;
  color: #ffffff !important;
}

.footer-cntctUs ul li,
.footer-srvcs ul li {
  color: rgba(255, 255, 255, 0.5);
}
.footer-srvcs a,
.footer-cntctUs a {
  text-decoration: none;
  color: inherit;
}

.link-footer {
  color: #00bcd4;
}
.icons {
  display: flex;
  gap: 20px;
  margin-top: -50px;
}
.social-icons a {
  background: rgba(255, 255, 255, 0.05);
  font-size: 1.2rem;
  padding: 7px;
  color: #3c8dba;
  border-radius: 50%;
}
.copyright {
  grid-column: span 3;
  margin-bottom: 1rem;
  margin-left: 2em;
}
/* Responciveness */

.hover-effect-links:hover {
  background-color: white;
  color: black;
}

.privacy-policy {
  margin-left: 7%;
  margin-right: 7%;
  padding: 20px;
  font-family: Arial, sans-serif;

  line-height: 1.6;
}

.privacy-policy h1 {
  font-size: 2.5rem;
  color: #222;
  text-align: center;
  margin-bottom: 10px;
}

.effective-date {
  font-size: 0.9rem;
  color: #777;
  text-align: center;
  margin-bottom: 30px;
}

.privacy-policy h2 {
  font-size: 1.5rem;
  margin-top: 20px;
  color: #444;
}

.privacy-policy h3 {
  font-size: 1.25rem;
  margin-top: 15px;

}

.privacy-policy p {
  margin-bottom: 15px;

}

.privacy-policy ul {
  list-style-type: disc;
  padding-left: 20px;
 
}

.privacy-policy li {
  margin-bottom: 10px;
}

.footer-social-icons {
  display: none;
  gap: 20px;
  margin-top: -30%;
}

/* Tablet */

@media screen and (max-width: 900px) {
  .Footer {
    grid-template-columns: repeat(2, 1fr);
    font-size: 0.8rem;
  }
  .footer-abtUs {
    grid-row: 2;
    grid-column: span 2;
    font-weight: 200;
    font-size: 0.5rem;
  }
  .copyright {
    font-weight: 200;
  }

  .copyright {
    grid-column: span 2;
    font-size: 0.5rem;
  }
  .Footer ul {
    padding: 0px;
  }

  .hover-effect-links:hover {
    background-color: white;
    color: black;
    cursor: pointer;
  }
}
/* Computer */

@media screen and (max-width: 1100px) {
  .footer-abtUs {
    font-size: 0.8rem;
  }
}
/* Mobile */

@media screen and (max-width: 592px) {
  .Footer {
    grid-template-columns: repeat(1, 1fr);
  }

  .copyright {
    grid-column: span 1;
    margin-left: 0px;
    margin-bottom: 0px;
  }
  .footer-abtUs {
    grid-row: 1;
    grid-column: span 1;
    text-align: initial;
  }
}

@media (max-width: 768px) {
  .privacy-policy {
    padding: 10px;
  }

  .privacy-policy h1 {
    font-size: 2rem;
  }

  .privacy-policy h2 {
    font-size: 1.25rem;
  }

  .privacy-policy h3 {
    font-size: 1.1rem;
  }

  .Footer {
    display: flex;
    flex-direction: column; /* Stack all footer sections vertically */
    gap: 20px; /* Add space between footer sections */
  }

  .footer-abtUs,
  .footer-srvcs,
  .footer-cntctUs {
    width: 100%; /* Ensure each section takes full width */
    display: flex;
    flex-direction: column; /* Stack items inside each section vertically */
  }

  .footer-point-headings {
    font-size: 1.25rem;
    font-weight: bold;
  }
  /* 
  .footer-list-items {
    margin-bottom: 8px;     
  } */

  .footer-list-items a,
  .footer-list-items a:hover {
    text-decoration: underline;
  }

  .footer-list-items a:hover {
    color: #007bff; /* Color change on hover */
  }

  /* Move social icons section to the last */
  .footer-social-icons {
    display: flex;
    justify-content: center; /* Align icons in the center */
    gap: 15px; /* Space between social icons */
  }

  .social-icons {
    font-size: 1.5rem;
  }

  .icons {
    display: none;
  }

  .copyright {
    text-align: center;
    font-size: 0.875rem;
    margin-top: 20px;
  }

  .copyright a {
    color: #007bff;
  }

  .copyright a:hover {
    text-decoration: underline;
  }
}
