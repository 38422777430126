.sqlServerTipsChild{
    /* margin-top: 100px; */
}

.ourSolutionCrd-1,
.ourSolutionCrd-2 {
  transition: transform 0.3s ease, box-shadow 0.3s ease; 
  position: relative; 
  margin: 1rem; 
}

.ourSolutionCrd-1:hover,
.ourSolutionCrd-2:hover {
  transform: translateY(-10px); 
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2); 
}


.tipContainer {
  display: flex; 
  align-items: flex-start;
  justify-content: space-between; 
  
}


/* For smaller screens (Mobile) */
@media (max-width: 770px) {
  .tipContainer {
    flex-direction: column; 
    align-items: flex-start; 
  }

  .tipImage {
    width: 100%;
    margin-bottom: 15px; 
  }

  .content {
    order: 1; 
  }
/* 
  .NewImg {
    order: -1; 
    width: 100%; 
  } */


}




